<template>
  <div class="page-container categories-list">
    <div class="page-title-container">
      <h1 class="page-title">Proveedores</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div class="d-flex d-lg-none justify-content-between mb-2">
          <div>
            <button
              type="button"
              class="button-tooltip d-lg-none m-0"
              v-b-tooltip.hover
              title="Recargar"
              @click="loadData()"
            >
              <RefreshTableIcon />
            </button>
          </div>

          <b-button
            :to="{ name: 'config-providers-create' }"
            variant="none"
            class="button button-primary"
            >Agregar</b-button
          >
        </div>

        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start"
        >
          <div class="first-section w-100 mt-2 mt-md-0">
            <div class="d-flex justify-content-between align-items-center">
              <div
                class="d-flex align-items-center justify-content-between filter"
                @click="showFilters = !showFilters"
              >
                <FiltersIcon />
                <div>Filtrar proveedores</div>
              </div>
            </div>
          </div>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>

          <section class="w-100 d-flex align-items-center">
            <button
              type="button"
              class="button-tooltip d-none d-lg-block"
              v-b-tooltip.hover
              title="Recargar"
              @click="loadData()"
            >
              <RefreshTableIcon />
            </button>

            <SearchBar
              placeholder="Buscar por número de documento, nombre o razón..."
              @search="onSearch"
              @input="search = $event"
              class="w-100"
            />

            <div class="d-none d-lg-block">
              <b-button
                :to="{ name: 'config-providers-create' }"
                variant="none"
                class="button button-primary ml-lg-2"
                >Agregar</b-button
              >
            </div>
          </section>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="p-0 bg-transparent border-0"
              @click="deleteFilter(name)"
            >
              <CloseBorderRoundedIcon class="delete-filter-item" />
            </button>
          </span>
        </div>
      </div>
      <div class="table-responsive mb-0">
        <table class="custom-table w-100">
          <thead>
            <tr>
              <th>Tipo de documento</th>
              <th class="pl-md-4">N° de documento</th>
              <th class="pl-md-4">Nombre / Razón social</th>
              <th>Estado</th>
              <th class="pr-md-4">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center">
                {{ getIdentifierType(o.identifierType).label }}
              </td>
              <td class="text-center pl-md-4">{{ o.identifier }}</td>
              <td class="text-center pl-md-4">{{ o.name }}</td>
              <td class="text-center">
                <span :class="`badge ${getStatus(o.isActive).color}`">{{
                  getStatus(o.isActive).label
                }}</span>
              </td>

              <td class="text-center d_option pr-md-4">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem :to="{ name: 'config-providers-edit', params: { id: o.id } }" text="Editar" />
                    <DropdownItem
                      :text="getStatus(o.isActive).action"
                      @click="openChangeStatus(o)"
                    />
                    <DropdownItem text="Eliminar" @click="openDelete(o)" />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="table-footer d-flex justify-content-end align-items-center"
        v-if="list.length"
      >
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  Util,
  ConfirmationModalMixin,
  PaginationMixin,
  FilterMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  // DownloadTableIcon,
  ConfirmationModal,
  FilterMenu,
  FiltersIcon,
  CloseBorderRoundedIcon,
  RefreshTableIcon,
} from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";

import { SupplierService } from "@/core/services";

export default {
  components: {
    SearchBar,
    FilterMenu,
    ConfirmationModal,
    DropdownTable,
    DropdownItem,
    // DownloadTableIcon,
    FiltersIcon,
    CloseBorderRoundedIcon,
    RefreshTableIcon,
  },
  mixins: [PaginationMixin, ConfirmationModalMixin, FilterMixin],
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Estado",
          key: "isActive",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.ProviderStatus.ACTIVE,
            LocalConstants.ProviderStatus.INACTIVE,
          ],
        },
      ],
    };
  },
  methods: {
    getIdentifierType(key) {
      return Util.searchValue(key, Constants.IdentifierType);
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "id,asc",
          search: this.search,
          ...this.filters,
        };
        const response = await SupplierService.list(params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.ProviderStatus);
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === "delete") this.deleteSerie();
      else if (this.confirmationModal.action === "update") this.changeStatus();
    },
    async changeStatus() {
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (
          await SupplierService.toggleStatus(
            this.selectedOption.id,
            !this.selectedOption.isActive
          )
        ).payload;

        const provider = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (provider) {
          const index = this.list.indexOf(provider);
          this.$set(this.list, index, resp);
        }
        Alert.success("Estado actualizado correctamente");
        this.$store.commit('app/setSuppliers', null)
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    openChangeStatus(o) {
      this.selectedOption = o;
      this.openConfirmationModal({
        title: "Cambiar estado",
        description: "¿Está seguro que desea cambiar el estado del proveedor?",
        acceptText: "Aceptar",
        action: "update",
      });
    },
    openDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar", action: "delete" });
    },
    async deleteSerie() {
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (await SupplierService.delete(this.selectedOption.id))
          .payload;

        const provider = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (provider) {
          const index = this.list.indexOf(provider);
          this.list.splice(index, 1);
          this.total--;
        }
        this.$store.commit('app/setSuppliers', null)
      } catch (ex) {
        console.error(ex);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  computed: {
    showFiltersList() {
      return Object.keys(this.filters).length >= 1;
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/categories-list.styl"
</style>
