<template>
  <section class="order-details" v-if="order">
    <div class="dashboard-banner">
      <h1 class="dashboard-title">Pedido N° {{ order.correlationId }}</h1>
    </div>

    <div class="card-details">
      <section class="section">
        <h2 class="subtitle my-0">Información del cliente</h2>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Cliente</label>
            <p class="text">
              {{ `${order.customerFirstName} ${order.customerLastName}` }}
            </p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Documento de identidad</label>
            <p class="text">
              {{ getIdentifierType(order.customerIdentifierType).label }} -
              {{ order.customerIdentifier }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Celular</label>
            <p class="text">{{ order.customerMobile }}</p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Correo electrónico</label>
            <p class="text">{{ order.customerEmail }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Referencia de registro</label>
            <p class="text">{{ order.customerReference }}</p>
          </div>
        </div>
      </section>

      <div class="section">
        <h2 class="subtitle">Dirección de entrega</h2>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Contacto</label>
            <p class="text">
              {{ `${order.addressFirstName} ${order.addressLastName}` }}
            </p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">Celular</label>
            <p class="text">
              {{ order.addressMobile }}
            </p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">Dirección</label>
            <p class="text">{{ order.addressAddress }}</p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">Referencia</label>
            <p class="text">
              {{ order.addressReference }}
            </p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">Tipo de dirección</label>
            <p class="text">
              {{ getAddressType(order.addressType).label }}
            </p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">Departamento / Interior</label>
            <p class="text">
              {{ order.addressInterior }}
            </p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">Departamento</label>
            <p class="text">{{ order.department }}</p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Provincia</label>
            <p class="text">
              {{ order.province }}
            </p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">Distrito</label>
            <p class="text">{{ order.district }}</p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">Urbanización</label>
            <p class="text">{{ order.addressUrbanization }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Confirmó dirección</label>
            <p class="text">
              {{
                order.addressCoordinates && order.addressShowMap ? "SI" : "NO"
              }}
            </p>
          </div>
        </div>

        <div class="row" v-show="order.addressShowMap && order.addressCoordinates">
          <div class="col-md-12 px-0">
            <label for="" class="label">Ubicación en el mapa</label>

            <div class="text">
              <GmapMap ref="mapRef" :options="mapOptions" :center="{ lat: -12.125584, lng: -77.021367 }" :zoom="2"
                :initialZoom="true" :clickable="true" style="width: 100%; height: 350px; margin-bottom: 32px"
                class="map">
                <GmapMarker :position="order.addressCoordinates" :clickable="false" :draggable="false" />
              </GmapMap>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <h2 class="subtitle">Facturación y pago</h2>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Método de pago</label>
            <p class="text text-uppercase">
              {{ getPaymentLabel(order.paymentMethodId) }}
            </p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Desea Factura</label>
            <p class="text">
              {{ order.billingType == "INVOICE" ? "SI" : "NO" }}
            </p>
          </div>
        </div>
        <div class="row" v-if="order.billingType == 'INVOICE'">
          <div class="col-md-6">
            <label for="" class="label">Razón social</label>
            <p class="text">
              {{ order.billingName }}
            </p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">Documento de identidad</label>
            <p class="text">
              {{ getIdentifierType(order.billingIdentifierType).label }} -
              {{ order.billingIdentifier }}
            </p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">Dirección</label>
            <p class="text">
              {{ order.billingAddress }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Subtotal</label>
            <p class="text" v-decimal:pen="order.subtotal"></p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">IGV</label>
            <p class="text" v-decimal:pen="order.taxes"></p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">Total compra</label>
            <p class="text" v-decimal:pen="order.total"></p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">Costo de entrega</label>
            <p class="text" v-decimal:pen="order.delivery"></p>
          </div>
          <div class="col-md-6">
            <label for="" class="label">Pago total</label>
            <p class="text" v-decimal:pen="order.totalPlusDelivery"></p>
          </div>
        </div>
      </div>

      <div class="section">
        <h2 class="subtitle">Descripción de pedido</h2>

        <div class="table-responsive">
          <table class="custom-table order-products w-100">
            <thead>
              <tr>
                <th class="pl-4">Imagen</th>
                <th>Descripción</th>
                <th class="px-3">Cantidad</th>
                <th class="px-3">Precio unitario</th>
                <th class="pr-4">Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="o in order.items" :key="o.id">
                <td class="text-center pl-md-4">
                  <div class="table-image cursor-pointer" @click="setShowGalleryModal(true, o.productImage)"
                    v-if="o.productImage">
                    <img :src="o.productImage" alt="" />
                  </div>
                </td>
                <td class="pl-5">
                  <p class="w-75 my-0">{{ o.productName }}</p>
                </td>
                <td class="text-center">{{ o.quantity }}</td>
                <td class="text-center" v-decimal:pen="o.price"></td>
                <td class="text-center pr-3" v-decimal:pen="o.total"></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row buttons-container">
          <div class="col text-right">
            <button @click="$router.push({ name: 'orders-list' })" class="button button-light">
              Volver
            </button>
          </div>
        </div>
      </div>
    </div>

    <GalleryModal :show="showGalleryModal" @hide="setShowGalleryModal(false)" :image="selectedOption"
      v-if="selectedOption" />
  </section>
</template>

<script>
import { Constants as LocalConstants } from "@/core/utils";
import { Alert, Constants, Util } from "wize-admin";

import { OrderService } from "@/core/services";

import GalleryModal from "@/core/components/common/GalleryModal";

export default {
  data() {
    return {
      orderId: this.$route.params.id,
      order: null,
      showGalleryModal: false,
      selectedOption: null,
      mapOptions: {
        map: null,
        minZoom: 18,
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit.station",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
    };
  },
  components: {
    GalleryModal,
  },
  methods: {
    getIdentifierType(key) {
      return Util.searchValue(key, Constants.IdentifierType);
    },
    getAddressType(key) {
      return LocalConstants.addressTypes.find((item) => item.key == key);
    },
    async load() {
      this.$store.dispatch("app/loading", true);

      try {
        let response = await OrderService.get(this.orderId);

        if (response.status === "OK") {
          const data = response.payload;
          this.order = Object.assign({}, this.order, data);
          if (data.addressCoordinates && data.addressShowMap) {
            const coordinates = data.addressCoordinates.split(",");
            this.order.addressCoordinates = {
              lat: Number(coordinates[0]),
              lng: Number(coordinates[1]),
            };
            this.fitBounds(this.order.addressCoordinates);
          }
        }
      } catch (error) {
        console.error(error);
        let message = error.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () => this.$router.push({ name: "orders-list" }));
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    getPaymentLabel(id) {
      return LocalConstants.PaymentMethods[id]?.label || "";
    },
    setShowGalleryModal(value, image) {
      if (value) {
        this.selectedOption = image;
      } else {
        this.selectedOption = null;
      }

      this.showGalleryModal = value;
    },
    fitBounds(coordinates) {
      if (!this.$refs.mapRef) {
        setTimeout(() => {
          this.fitBounds(coordinates);
        }, 1000);
        return;
      }
      this.$refs.mapRef.$mapPromise.then((map) => {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(coordinates);
        map.fitBounds(bounds);
        setTimeout(() => {
          map.setZoom(2);
        }, 1000);
      });
    },
  },
  async mounted() {
    await this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/order-details.styl"
</style>
