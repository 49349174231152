<template>
  <div>
    <div :class="`wrapper-page${isMenuOpen ? '' : '-closeSidebar'}`">
      <!-- Mobile -->
      <PageSidebarMobile :show="isMenuOpen && currentDevice != 'desktop'" />

      <!-- Desktop -->
      <page-sidebar
        :isMenuOpen="isMenuOpen"
        :tooltipType="tooltipType"
        @toggleMenu="toggleMenu"
        v-if="currentDevice == 'desktop'"
      >
        <template #custom-header>
          <img
            src="@/core/assets/images/layout/logo-razzil.png"
            alt="Razzil"
            class="sidebar-logo"
          />
          <span class="sidebar-title w-50">RAZZIL</span>
        </template>

        <template #list-items>
          <!-- Portada -->
          <router-link
            :to="{ name: 'dashboard' }"
            tag="li"
            exact-active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'home-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <span class="menu-icon">
                <i class="ri-home-5-line"></i>
              </span>

              <span class="sidebar-item-text">Portada</span>

              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'home-item'"
                text="Portada"
              />
            </div>
          </router-link>

          <!-- Pedidos -->
          <router-link
            :to="{ name: 'orders-list' }"
            v-if="$can('manage', 'orders')"
            tag="li"
            active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'orders-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <span class="menu-icon">
                <i class="ri-shopping-cart-line"></i>
              </span>

              <span class="sidebar-item-text">Pedidos</span>
              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'orders-item'"
                text="Pedidos"
              />
            </div>
          </router-link>

          <!-- Clientes -->
          <router-link
            event=""
            tag="li"
            active-class="active"
            to="/clientes"
            v-if="$can('manage', 'clients')"
            class="sidebar-item d-flex align-items-center"
            @click.native.prevent="
              currentSubmenu === 'clients'
                ? setCurrentSubmenu(null)
                : setCurrentSubmenu('clients')
            "
          >
            <div
              class="d-flex flex-column justify-content-center sidebar-item-container"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex"
                  @mouseover="tooltipType = 'clients-item'"
                  @mouseleave="tooltipType = null"
                >
                  <span class="menu-icon">
                    <i class="ri-team-line"></i>
                  </span>

                  <span class="sidebar-item-text">Clientes</span>

                  <CustomTooltip
                    custom-class="tooltip-sidebar"
                    v-if="!isMenuOpen && tooltipType === 'clients-item'"
                    text="Clientes"
                  />
                </div>
              </div>
              <span
                :class="`sidebar-item-description ${
                  isCurrent('clients') ? 'active' : ''
                }`"
              >
                Lista
              </span>
            </div>
            <div v-if="isMenuOpen">
              <span v-if="isCurrent('clients')">
                <i class="ri-arrow-up-s-line"></i>
              </span>
              <span v-else>
                <i class="ri-arrow-down-s-line"></i>
              </span>
            </div>
          </router-link>

          <!-- Submenu clientes -->
          <section v-if="$can('manage', 'clients')">
            <ul
              :class="`sidebar-submenu ${
                isCurrent('clients') ? 'active' : ''
              } pl-0`"
            >
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="clients-list"
                v-if="$can('manage', 'clients-list')"
                text="Lista"
              ></sub-item>
              <!-- <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="clients-discounts"
                text="Descuentos"
              ></sub-item> -->
            </ul>
          </section>

          <!-- Productos -->
          <router-link
            event=""
            tag="li"
            active-class="active"
            to="/productos"
            v-if="$can('manage', 'products')"
            class="sidebar-item d-flex align-items-center"
            @click.native.prevent="
              currentSubmenu === 'products'
                ? setCurrentSubmenu(null)
                : setCurrentSubmenu('products')
            "
          >
            <div
              class="d-flex flex-column justify-content-center sidebar-item-container"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex"
                  @mouseover="tooltipType = 'products-item'"
                  @mouseleave="tooltipType = null"
                >
                  <span class="menu-icon">
                    <i class="ri-shopping-bag-line"></i>
                  </span>

                  <span class="sidebar-item-text">Productos</span>

                  <CustomTooltip
                    custom-class="tooltip-sidebar"
                    v-if="!isMenuOpen && tooltipType === 'products-item'"
                    text="Productos"
                  />
                </div>
              </div>
              <span
                :class="`sidebar-item-description ${
                  isCurrent('products') ? 'active' : ''
                }`"
              >
                Lista, cargar productos, cargar ...
              </span>
            </div>
            <div v-if="isMenuOpen">
              <span v-if="isCurrent('products')">
                <i class="ri-arrow-up-s-line"></i>
              </span>
              <span v-else>
                <i class="ri-arrow-down-s-line"></i>
              </span>
            </div>
          </router-link>

          <!-- Submenu productos -->
          <section v-if="$can('manage', 'products')">
            <ul
              :class="`sidebar-submenu ${
                isCurrent('products') ? 'active' : ''
              } pl-0`"
            >
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="products-list"
                v-if="$can('manage', 'products-list')"
                text="Lista"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="load-products"
                v-if="$can('manage', 'load-products')"
                text="Cargar productos"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="update-stock"
                v-if="$can('manage', 'update-stock')"
                text="Actualizar stock"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="update-prices"
                v-if="$can('manage', 'update-prices')"
                text="Actualizar precios"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="quotes"
                v-if="$can('manage', 'quotes')"
                text="Cotizador"
              ></sub-item>
            </ul>
          </section>

          <!-- Mantenimiento -->
          <router-link
            event=""
            tag="li"
            active-class="active"
            v-if="$can('manage', 'config')"
            to="/mantenimiento"
            class="sidebar-item d-flex align-items-center"
            @click.native.prevent="
              currentSubmenu === 'config'
                ? setCurrentSubmenu(null)
                : setCurrentSubmenu('config')
            "
          >
            <div
              class="d-flex flex-column justify-content-center sidebar-item-container"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex"
                  @mouseover="tooltipType = 'config-item'"
                  @mouseleave="tooltipType = null"
                >
                  <span class="menu-icon">
                    <i class="ri-edit-line"></i>
                  </span>

                  <span class="sidebar-item-text">Mantenimiento</span>

                  <CustomTooltip
                    custom-class="tooltip-sidebar"
                    v-if="!isMenuOpen && tooltipType === 'config-item'"
                    text="Mantenimiento"
                  />
                </div>
              </div>
              <span
                :class="`sidebar-item-description ${
                  isCurrent('config') ? 'active' : ''
                }`"
              >
                Usuarios, categorías, atributos, proveedores
              </span>
            </div>
            <div v-if="isMenuOpen">
              <span v-if="isCurrent('config')">
                <i class="ri-arrow-up-s-line"></i>
              </span>
              <span v-else>
                <i class="ri-arrow-down-s-line"></i>
              </span>
            </div>
          </router-link>

          <!-- Submenu mantenimiento -->
          <section v-if="$can('manage', 'config')">
            <ul
              :class="`sidebar-submenu ${
                isCurrent('config') ? 'active' : ''
              } pl-0`"
            >
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-users-list"
                v-if="$can('manage', 'config-users-list')"
                text="Usuarios"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-categories-list"
                v-if="$can('manage', 'config-categories-list')"
                text="Categorías"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-attributes-list"
                v-if="$can('manage', 'config-attributes-list')"
                text="Atributos"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-providers-list"
                v-if="$can('manage', 'config-providers-list')"
                text="Proveedores"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-store-list"
                v-if="$can('manage', 'config-store-list')"
                text="Locales"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-banners-list"
                v-if="$can('manage', 'config-banners-list')"
                text="Banners"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-payment-methods"
                v-if="$can('manage', 'config-payment-methods')"
                text="Métodos de pago"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-delivery-list"
                v-if="$can('manage', 'config-delivery-list')"
                text="Entregas y envíos"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-pages-list"
                v-if="$can('manage', 'config-pages-list')"
                text="Páginas"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-menu-settings"
                v-if="$can('manage', 'config-menu-settings')"
                text="Configuración de menú"
              ></sub-item>
            </ul>
          </section>

          <!-- Apariencia -->
          <router-link
            event=""
            tag="li"
            active-class="active"
            v-if="$can('manage', 'appearance')"
            to="/apariencia"
            class="sidebar-item d-flex align-items-center"
            @click.native.prevent="
              currentSubmenu === 'appearance'
                ? setCurrentSubmenu(null)
                : setCurrentSubmenu('appearance')
            "
          >
            <div
              class="d-flex flex-column justify-content-center sidebar-item-container"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex"
                  @mouseover="tooltipType = 'appearance-item'"
                  @mouseleave="tooltipType = null"
                >
                  <span class="menu-icon">
                    <i class="ri-paint-brush-line"></i>
                  </span>

                  <span class="sidebar-item-text">Apariencia</span>

                  <CustomTooltip
                    custom-class="tooltip-sidebar"
                    v-if="!isMenuOpen && tooltipType === 'appearance-item'"
                    text="Mantenimiento"
                  />
                </div>
              </div>
              <span
                :class="`sidebar-item-description ${
                  isCurrent('appearance') ? 'active' : ''
                }`"
              >
                Menús
              </span>
            </div>
            <div v-if="isMenuOpen">
              <span v-if="isCurrent('appearance')">
                <i class="ri-arrow-up-s-line"></i>
              </span>
              <span v-else>
                <i class="ri-arrow-down-s-line"></i>
              </span>
            </div>
          </router-link>

          <!-- Submenu apariencia -->
          <section v-if="$can('manage', 'appearance')">
            <ul
              :class="`sidebar-submenu ${
                isCurrent('appearance') ? 'active' : ''
              } pl-0`"
            >
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="appearance-menus"
                v-if="$can('manage', 'appearance-menus')"
                text="Menús"
              ></sub-item>
            </ul>
          </section>

          <!-- Herramientas -->
          <router-link
            event=""
            tag="li"
            active-class="active"
            v-if="$can('manage', 'tools')"
            to="/herramientas"
            class="sidebar-item d-flex align-items-center"
            @click.native.prevent="
              currentSubmenu === 'tools'
                ? setCurrentSubmenu(null)
                : setCurrentSubmenu('tools')
            "
          >
            <div
              class="d-flex flex-column justify-content-center sidebar-item-container"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex"
                  @mouseover="tooltipType = 'tools-item'"
                  @mouseleave="tooltipType = null"
                >
                  <span class="menu-icon">
                    <i class="ri-hammer-line"></i>
                  </span>

                  <span class="sidebar-item-text">Herramientas</span>

                  <CustomTooltip
                    custom-class="tooltip-sidebar"
                    v-if="!isMenuOpen && tooltipType === 'tools-item'"
                    text="Mantenimiento"
                  />
                </div>
              </div>
              <span
                :class="`sidebar-item-description ${
                  isCurrent('tools') ? 'active' : ''
                }`"
              >
                Cotizador, Redirecciones
              </span>
            </div>
            <div v-if="isMenuOpen">
              <span v-if="isCurrent('tools')">
                <i class="ri-arrow-up-s-line"></i>
              </span>
              <span v-else>
                <i class="ri-arrow-down-s-line"></i>
              </span>
            </div>
          </router-link>

          <!-- Submenu herramientas -->
          <section v-if="$can('manage', 'tools')">
            <ul
              :class="`sidebar-submenu ${
                isCurrent('tools') ? 'active' : ''
              } pl-0`"
            >
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="tools-quotes-list"
                v-if="$can('manage', 'tools-quotes-list')"
                text="Cotizador"
              ></sub-item>

              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="tools-redirections-list"
                v-if="$can('manage', 'tools-redirections-list')"
                text="Redirecciones"
              ></sub-item>

              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="tools-biolink"
                v-if="$can('manage', 'tools-biolink')"
                text="Biolink"
              ></sub-item>
            </ul>
          </section>
        </template>

        <template #footer>
          <section class="page-sidebar-footer">
            <router-link
              :to="{ name: 'general-configuration' }"
              v-if="$can('manage', 'general-configuration')"
              tag="li"
              exact-active-class="active"
              class="d-flex sidebar-item sidebar-item-footer"
              @mouseover.native="tooltipType = 'general-configuration-item'"
              @mouseleave.native="tooltipType = null"
            >
              <div class="d-flex align-items-center">
                <ConfigIcon class="sidebar-icon" />

                <span class="sidebar-item-text">Configuración general</span>

                <CustomTooltip
                  custom-class="tooltip-sidebar"
                  v-if="
                    !isMenuOpen && tooltipType === 'general-configuration-item'
                  "
                  text="Configuración general"
                />
              </div>
            </router-link>

            <div
              class="d-flex justify-content-between align-items-center sidebar-item sidebar-item-footer"
              v-if="isMenuOpen"
            >
              <span class="plan">{{ getPlan().label }}</span>

              <!--<button
                type="button"
                class="text-white plan-link bg-transparent border-0"
                @click="showModalPaymentPlans = true"
              >
                Modificar
              </button>-->
            </div>
          </section>
        </template>
      </page-sidebar>

      <div class="wrapper-page__content">
        <page-header />
        <div class="wrapper-page__inner">
          <b-container fluid>
            <slot></slot>
          </b-container>
        </div>
      </div>

      <page-footer />
    </div>

    <ModalPaymentPlans
      :show="showModalPaymentPlans"
      @hide="showModalPaymentPlans = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import {
  ConfigIcon,
  CustomTooltip,
  PageFooter,
  PageSidebar,
  SubItem
} from "wize-admin";

import PageHeader from "./PageHeader";
import PageSidebarMobile from "./layout/PageSidebarMobile";
import ModalPaymentPlans from "./payment/ModalPaymentPlans";
import { Constants as LocalConstants } from "@/core/utils";

export default {
  name: "PageBase",
  components: {
    PageHeader,
    PageSidebarMobile,
    PageSidebar,
    PageFooter,
    CustomTooltip,
    SubItem,
    ConfigIcon,
    ModalPaymentPlans
  },
  data() {
    return {
      matchMediaMd: window.matchMedia("(max-width: 768px)"),
      tooltipType: null,
      showModalPaymentPlans: false,
    };
  },
  computed: {
    ...mapGetters({
      currentSubmenu: "app/getCurrentSubmenu",
      isMenuOpen: "app/isMenuOpen",
      getCurrentPlan: "app/getCurrentPlan",
      currentDevice: "app/getCurrentDevice",
    }),
  },
  methods: {
    calcResize() {
      this.setCurrentDevice();

      this.lastWidth = window.innerWidth;

      window.addEventListener("resize", () => {
        const currentWidth = window.innerWidth;

        if (currentWidth !== this.lastWidth) {
          this.setCurrentDevice();
          this.lastWidth = currentWidth;
        }
      });
    },
    setCurrentDevice() {
      if (screen.width < 992) {
        this.$store.commit("app/setCurrentDevice", "mobile");
        this.toggleMenu(false);
      } else {
        this.$store.commit("app/setCurrentDevice", "desktop");
        this.toggleMenu(true);
      }
    },
    isMatchMedia() {
      if (this.matchMediaMd.matches) {
        this.setMenu(false);
      } else {
        this.setMenu(true);
      }
    },
    getPlan() {
      return LocalConstants.Plans.find(
        (item) => item.key == this.getCurrentPlan
      );
    },
    isCurrent(submenu) {
      return this.currentSubmenu.includes(submenu);
    },
    ...mapMutations({
      setCurrentSubmenu: "app/setCurrentSubmenu",
      setMenu: "app/setMenu",
      toggleMenu: "app/toggleMenu",
    }),
    hideRecaptchaBadge() {
      if (this.$recaptchaInstance) {
        this.$recaptchaInstance.hideBadge();
      } else {
        setTimeout(() => {
          this.hideRecaptchaBadge();
        }, 500);
      }
    },
  },
  created() {
    this.matchMediaMd.addListener(this.isMatchMedia);
  },
  async mounted() {
    this.hideRecaptchaBadge();
    this.calcResize();
  },
};
</script>

<style lang="stylus">
.sidebar-item
  border-botton: 1px solid #2b2b2c !important
.plan
  max-width 100% !important
</style>
