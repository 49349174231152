<template>
  <b-modal
    id="modal-order-status"
    v-model="showLocal"
    @hidden="hide"
    dialog-class="modal-order-status"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    content-class="modal-order-status-content"
    size="lg"
  >
    <div
      class="d-flex justify-content-between aling-items-center modal-header-detail pl-4 pr-4"
    >
      <div class="modal-title-left-primary my-auto px-2" v-if="selectedOption">
        Cambiar estado de pedido {{ selectedOption.correlationId }}
      </div>
      <b-button
        class="icon-close icon-close-primary my-auto bg-transparent border-0"
        @click="hide"
        aria-label="Close"
      >
        <CloseModalIcon class="fill-white" />
      </b-button>
    </div>

    <div class="modal-body-detail">
      <simple-alert
        text="Asegúrese de marcar la opción correcta porque se enviarán correos automáticos al cliente."
        type="light-gray"
      >
        <template slot="icon">
          <WarningAlertIcon />
        </template>
      </simple-alert>

      <form @submit.prevent="updateStatus()" class="custom-form mt-4">
        <div class="form-group mb-0 d-flex align-items-center">
          <label for="status" class="label-status m-0">Estado de pedido</label>

          <div class="w-100 position-relative">
            <FormSelect
              id="identifierType"
              v-model="form.status"
              :items="statusList"
              defaultOption="Elegir estado"
              :showError="$v.form.status.$error"
              class="ml-3 mb-0"
            />
            <form-error
              message="Estado de pedido es requerido"
              class="form-error"
              v-if="$v.form.status.$error && !$v.form.status.required"
            ></form-error>
          </div>
        </div>

        <div
          class="form-group-buttons text-right mb-0 px-0 pt-0"
          :class="
            $v.form.status.$error && !$v.form.status.required ? 'mt-5' : 'mt-4'
          "
        >
          <a href="" class="button button-light" @click.prevent="close()">
            Cancelar
          </a>

          <button class="button button-primary" type="submit">Guardar</button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import {
  Alert,
  ModalMixin,
  CloseModalIcon,
  WarningAlertIcon,
  FormSelect,
  FormError,
  SimpleAlert
} from "wize-admin";

import { required } from "vuelidate/lib/validators";

import { Constants as LocalConstants } from "@/core/utils";

import { OrderService } from '@/core/services'

export default {
  name: "ConversionsModal",
  mixins: [ModalMixin],
  data() {
    return {
      form: {
        status: null,
      },
      statusList: [
        LocalConstants.OrdersStatus.PENDING,
        LocalConstants.OrdersStatus.PAYMENT_CONFIRMED,
        LocalConstants.OrdersStatus.DELIVERED,
        LocalConstants.OrdersStatus.CANCELLED,
        LocalConstants.OrdersStatus.REJECTED,
      ],
    };
  },
  mounted() {
    this.form.status = this.selectedOption.status;
  },
  props: {
    selectedOption: Object,
  },
  components: {
    CloseModalIcon,
    SimpleAlert,
    WarningAlertIcon,
    FormSelect,
    FormError,
  },
  validations: {
    form: {
      status: { required },
    },
  },
  watch: {
    selectedOption: {
      deep: true,
      handler: function (val) {
        this.form.status = val.status;
      },
    },
  },
  methods: {
    async updateStatus() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      try {
        this.$store.dispatch("app/loading", true);

        let response = await OrderService.toggleStatus(this.selectedOption.id, this.form.status)

        if(response.status == 'OK') {
          Alert.success("Se almacenó la información correctamente")

          // Emitir evento para actualizar tabla
          this.$emit('updated', {id: this.selectedOption.id, status: this.form.status})
          this.close()
        }
      } catch (error) {
        Alert.error(error)
      } finally {
        this.$store.dispatch("app/loading", false)
      }
    },
    close() {
      this.$v.$reset();

      // Reiniciar valor de select
      if(this.selectedOption.status != this.form.status) {
        this.form.status = this.selectedOption.status
      }

      // this.$emit('clearSelectedOption')

      this.hide();
    },
  },
};
</script>
<style lang="stylus">
@import "../Styles/modal-order-status.styl"
</style>
