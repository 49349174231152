import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './auth';
import appModule from './app';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: authModule,
    app: appModule
  }
})
