<template>
  <div class="page-container categories-list">
    <div class="page-title-container">
      <h1 class="page-title">Páginas</h1>
    </div>

    <div class="table-container">
      <div
        class="table-header d-flex flex-column flex-md-row justify-content-end align-items-start"
      >
        <section class="d-flex align-items-center">
          <button
            type="button"
            class="button-tooltip"
            v-b-tooltip.hover
            title="Recargar"
            @click="loadData()"
          >
            <RefreshTableIcon />
          </button>

          <SearchBar
            placeholder="Buscar por título"
            @search="onSearch"
            @input="search = $event"
          />
        </section>
      </div>
      <div class="table-responsive mb-0">
        <table class="custom-table w-100">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Link</th>
              <th>Última actualización</th>
              <th>Título SEO</th>
              <th class="pr-md-4">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center">
                {{ o.name }}
              </td>
              <td class="text-center">
                <a :href="setUrl(o)" target="_blank">
                  <LinkIcon class="fill-gray" />
                </a>
              </td>
              <td class="text-center">
                {{ o.updatedAt || "-" }}
              </td>
              <td class="text-center">
                {{ o.seoTitle || "-" }}
              </td>

              <td class="text-center d_option pr-md-4">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem :to="{ name: 'config-pages-edit', params: { id: o.id } }" text="Editar"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="table-footer d-flex justify-content-end align-items-center"
        v-if="list.length"
      >
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  PaginationMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  RefreshTableIcon,
  LinkIcon
} from "wize-admin";

import { PostService } from "@/core/services";

export default {
  components: {
    SearchBar,
    DropdownTable,
    DropdownItem,
    RefreshTableIcon,
    LinkIcon
  },
  mixins: [PaginationMixin],
  data() {
    return {
      list: [],
    };
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "id,asc",
          search: this.search,
        };
        const response = await PostService.list(params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    setUrl(item) {
      return `${process.env.VUE_APP_WEB_URL}/${item.slug}`
    }
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped></style>
